.news-block {
	margin-bottom: -1px;
	color: $white;
	background-color: $bg-grey;

	&__content {
		padding-top: 70px;
		padding-bottom: 60px;

		@media (max-width: 575px) {
			padding-top: 45px;
		}
	}

	&__top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	&__heading {
		font-weight: 700;
		font-style: italic;
		font-size: 32px;
		margin: 0;
	}

	&__all {

		@media (max-width: 575px) {
			&_device_desktop {
				display: none;
			}
			&_device_mobile {
				background: $yellow;
			}
		}
	}

	&__link {
		text-align: center;

		@media (min-width: 576px) {
			display: none;
		}
	}

	&__row {
		@media (max-width: 767px) {
			max-width: 510px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	&__item {

		@media (max-width: 991px) {
			margin-bottom: 40px;
		}

		@media (max-width: 767px) {
			padding: 0;
		}
	}

	&__item-more {
		display: none;
	}

	@media (max-width: 767px) and (min-width: 576px) {
		&__item-img {
			float: left;
			margin-right: 17px;
			margin-bottom: 0;
			padding-top: 32%;
			width: 260px;
		}
		&__item-text {
			display: block;
			overflow: hidden;
			font-size: 18px;
		}
		&__item-more {
			display: block;
			margin-top: 26px;
			padding-right: 10px;
			text-align: right;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}