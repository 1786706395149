.history {
	padding-bottom: 80px;

	&__title {
		margin-bottom: 93px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			margin-bottom: 40px;
			text-transform: none;
		}
	}

	&__carousel {
		position: relative;
		margin-bottom: 20px;

		@media (max-width: 767px) {
			margin-bottom: 50px;
		}
	}

	&__slide {
		width: 100%;
	}

	&__item {
		display: flex;
		flex-direction: row;
		max-width: 760px;
		margin: 0 auto;
		padding: 17px 26px 0;

		@media (max-width: 991px) {
			max-width: 600px;
		}

		@media (max-width: 767px) {
			display: block;
		}
	}

	&__date {
		position: relative;
		flex: 0 0 auto;
		font-weight: 700;
		font-style: italic;
		font-size: 80px;
		line-height: 67px;

		@media (max-width: 767px) {
			margin-bottom: 20px;
		}

		&::before {
			content: "";
			position: absolute;
			top: -15px;
			left: -18px;
			width: 8px;
			height: 40px;
			@include skew;
			background-color: $yellow;
		}
	}

	&__event {

		@media (min-width: 768px) {
			min-height: 300px;
			@include skew;
			margin-left: -23px;
		}

		@media (max-width: 767px) {
			font-style: italic;
			font-size: 18px;
		}
	}

	&__navigation {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;

		@media (max-width: 767px) {
			display: block;
			position: relative;
			padding: 20px;
			font-style: italic;
			font-size: 20px;
			text-align: center;
			color: $bg-light;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				right: -30px;
				left: -30px;
				height: 1px;
				background-color: $bg-light;
			}
		}
	}

	&__prev {
		display: inline-block;
		width: 40px;
		height: 36px;
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		text-align: center;
		vertical-align: middle;
		background-color: $yellow;
		@include skew;
		cursor: pointer;

		@media (max-width: 767px) {
			position: absolute;
			top: 19px;
			left: 0;
			@include no-skew;
			background: none;
			font-style: italic;
			line-height: 1.25;
			color: $bg-light;
			white-space: nowrap;
		}
	}

	&__next {
		display: inline-block;
		width: 40px;
		height: 36px;
		font-weight: 700;
		font-size: 20px;
		line-height: 34px;
		text-align: center;
		vertical-align: middle;
		background-color: $yellow;
		@include skew;
		cursor: pointer;

		@media (max-width: 767px) {
			position: absolute;
			top: 19px;
			right: 0;
			@include no-skew;
			background: none;
			font-style: italic;
			line-height: 1.25;
			color: $bg-light;
			white-space: nowrap;
		}
	}

	&__page {
		display: none;

		@media (max-width: 767px) {
			display: inline-block;
			vertical-align: top;
			margin: 0 8px;
			padding-top: 1px;
			font-weight: 400;
		}
	}

	&__arrow {
		display: inline-block;
		vertical-align: top;
	}

	&__disabled {
		visibility: hidden;
	}

	&__timeline {
		position: relative;

		@media (min-width: 576px) {
			margin: 0 15px 0 -5px;
			@include skew;
		}

		@media (max-width: 767px) {
			display: none;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 2px;
			background-color: $bg-light;

			@media (max-width: 575px) {
				right: -30px;
				left: -30px;
				height: 1px;
			}
		}
	}

	&__year {
		position: relative;
		width: 92px;
		padding: 20px 0 0 15px;
		font-weight: 700;
		font-style: italic;
		font-size: 20px;
		color: $bg-light;
		cursor: pointer;
		transition: font-size 0.3s ease;

		@media (max-width: 575px) {
			width: auto;
			font-weight: 400;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 4px;
			height: 21px;
			background-color: $bg-light;

			@media (max-width: 575px) {
				display: none;
			}
		}

		&_active {

			@media (min-width: 576px) {
				padding-left: 23px;
				font-size: 45px;
				color: $black;
				transition: font-size 0.3s ease;

				&::before {
					width: 7px;
					height: 32px;
					background-color: $yellow;
				}
			}
		}
	}

	&__year-text {
		display: block;
		min-height: 56px;
		@include skew-left;
	}

	&__next-slider {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		font-size: 20px;
		color: $bg-light;
		background-color: #ffffff;
		cursor: pointer;

		@media (max-width: 767px) {
			display: none;
		}

		&::before {
			content: "";
			position: absolute;
			top: -10px;
			left: -5px;
			width: 4px;
			height: 20px;
			@include skew;
			background-color: $bg-light;
		}
	}
}