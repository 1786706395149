.footer {
	flex: 0 0 auto;
	padding: 40px 0 45px;
	font-weight: 700;
	font-style: italic;
	font-size: 17px;
	color: $white;
	background-color: $bg-dark;

	@media (max-width: 767px) {
		background-color: #282828;
	}

	&__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@media (max-width: 991px) {
			flex-wrap: wrap;
			padding-left: 100px;
		}

		@media (max-width: 767px) {
			display: block;
			padding-left: 30px;
		}

		@media (max-width: 767px) and (min-width: 576px) {
			columns: 200px 2;
		}
	}

	&__skew {
		&-forward {
			@include skew;

			@media (max-width: 767px) {
				transform: none;
			}
		}

		&-backward {
			display: block;
			@include skew-left;

			@media (max-width: 767px) {
				transform: none;
			}
		}
	}

	&__logo {
		display: block;
		@include link-white;

		@media (max-width: 991px) {
			width: 40%;
			margin-bottom: 50px;
		}

		@media (max-width: 767px) {
			display: inline-block;
			width: auto;
		}

		@media (max-width: 767px) and (min-width: 576px) {
			margin-bottom: 30px;
		}
	}

	&__menu {
		@media (max-width: 991px) {
			width: 40%;
		}

		@media (max-width: 767px) {
			width: auto;
			-webkit-column-break-inside: avoid;
        	page-break-inside: avoid;
        	break-inside: avoid;
			line-height: 36px;
		}

		@media (max-width: 575px) {
			margin-bottom: 10px;
		}
	}

	&__language {
		display: none;
		margin-bottom: 50px;

		@media (max-width: 575px) {
			display: block;
		}
	}

	&__contacts {
		@media (max-width: 991px) {
			width: 40%;
		}

		@media (max-width: 767px) {
			width: auto;
		}

		@media (max-width: 767px) and (min-width: 576px) {
			padding-top: 129px;
		}
	}

	&__link {
		@include link-white-hovered;
	}

	&__social {
		font-weight: 700;
		font-size: 24px;

		@media (max-width: 1128px) {
			margin-right: 20px;
		}

		@media (max-width: 991px) {
			width: 40%;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__button {
		margin-bottom: 1px;
	}

	&__svg {
		fill: $white;

		.footer__button:hover & {
			fill: $yellow;
		}
	}

	&__fb {
		width: 8px;
		height: 18px;
	}

	&__in {
		width: 17px;
		height: 18px;
	}

	&__gp {
		width: 24px;
		height: 18px;
	}

	&__ig {
		margin-top: 5px;
		width: 24px;
		height: 19px;
		@include skew;
	}
}