.header {
	flex: 0 0 auto;
	position: relative;
	height: 50px;
	font-style: italic;
	color: $white;
	background: $bg-dark;

	@media (max-width: 767px) {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 7;
	}

	&_open {
		@media (max-width: 767px) {
			overflow: auto;
			bottom: 0;
			height: auto;
			background: $bg-grey;
		}
	}

	&__wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 50px;
		padding: 0 58px;

		@media (max-width: 1199px) {
			padding: 0 15px;
		}

		@media (max-width: 767px) {
			padding: 0 25px;

			.header_open & {
				background: $bg-dark;
			}
		}

	}

	&__logo {
		margin-right: auto;
	}

	&__nav {
		display: flex;
		flex-direction: row;
		margin-right: 23px;

		@media (max-width: 767px) {
			position: absolute;
			top: 50px;
			right: 0;
			left: 0;
			z-index: 4;
			display: none;
			margin-right: 0;
			background: $bg-grey;

			.header_open & {
				display: block;
			}
		}
	}

	&__menu {
		margin-right: 85px;

		@media (max-width: 1199px) {
			margin-right: 30px;
		}

		@media (max-width: 767px) {
			margin-right: 0;
		}
	}

	&__language {
		font-style: italic;
		font-size: 15.9px;
		@include link-white-hovered;

		@media (max-width: 767px) {
			display: block;
			margin-bottom: 10px;
			padding: 15px 25px 16px;
		}
	}

	&__contacts {
		display: none;
		padding: 0 25px 40px;
		font-size: 17px;

		@media (max-width: 767px) {
			display: block;
		}
	}

	&__search {
		display: block;
		width: 18px;
		height: 25px;
	}

	&__svg-search {
		width: 18px;
		height: 25px;
		fill: $white;

		path {
			fill: $white;
		}

		&:hover {
			fill: $yellow;
		}
	}

	&__burger {
		display: none;
		width: 27px;
		height: 19px;
		margin-left: 25px;

		.header_open & {
			height: 25px;
			background: none;

			&::before {
				content: "X";
				display: block;
				font-weight: 700;
				font-size: 30px;
				line-height: 25px;
				text-align: center;
				color: $yellow;
			}
		}

		@media (max-width: 767px) {
			display: block;
		}
	}

	&__svg-burger {
		width: 27px;
		height: 19px;
		fill: $white;

		.header_open & {
			display: none;
		}
	}

	&_open {

	}
}