.page-nav {
	margin-top: 85px;
	margin-bottom: 57px;

	@media (max-width: 767px) {
		margin: 0 -30px 30px;
		border-bottom: 1px solid $bg-light;
	}

	&__item {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 15px;

		@media (max-width: 767px) {
			display: block;
			margin-bottom: 0;
		}

		&:last-child {
			.page-nav__link::after {
				display: none;
			}
		}
	}

	&__link {
		position: relative;
		display: block;
		height: 23px;
		margin-left: -7px;
		padding: 0 27px;
		@include link-inherit;
		@include skew;
		font-weight: 700;
		font-style: italic;
		font-size: 16px;
		line-height: 22px;
		vertical-align: middle;

		@media (max-width: 767px) {
			height: 50px;
			margin-left: 0;
			font-size: 20px;
			line-height: 50px;
			vertical-align: middle;
			border-top: 1px solid $grey;
			@include no-skew;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: -5px;
			bottom: 0;
			width: 5px;
			background-color: $yellow;

			@media (max-width: 767px) {
				display: none;
			}
		}

		&_current {
			background-color: #c8c8c8;

			@media (max-width: 767px) {
				position: relative;
				background-color: $yellow;
				border-top: none;

				&::before {
					content: "";
					position: absolute;
					right: 0;
					bottom: -1px;
					left: 0;
					z-index: 2;
					height: 1px;
					background: $yellow;
				}
			}

			&::after {
				display: none;
			}
		}
	}

	&__text {
		display: inline-block;
		vertical-align: baseline;
		@include skew-left;

		@media (max-width: 767px) {
			@include no-skew;
			line-height: 1;
			vertical-align: middle;

		}
	}

	&__inner {
		@media (min-width: 768px) {
			display: block!important;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__arrow {
		width: 11px;
		height: 12px;
		margin-left: 10px;
		transform: rotate(0deg);
	}

	&__mobile {
		display: none;

		@media (max-width: 767px) {
			display: block;
		}

		.page-nav__link {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: baseline;
			width: 100%;
			padding-top: 15px;
			border-top: none;
		}

		&_open {
			.page-nav__arrow {
				transform: rotate(180deg);
				fill: $yellow;
			}
		}
	}
}