.menu {
	font-size: 15px;

	&__item {
		display: inline-block;
		margin-right: 11px;

		@media (max-width: 767px) {
			display: block;
			margin-right: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		@include link-white-hovered;

		@media (max-width: 767px) {
			display: block;
			padding: 15px 25px 16px;
			border-bottom: 1px solid $bg-dark;
		}

		&_current {
			color: $yellow;

			&:link,
			&:active,
			&:hover {
				color: $yellow;
			}
		}
	}

	&__submenu-opener {
		@media (max-width: 767px) {
			&::after {
				content: "V";
				display: inline-block;
				vertical-align: middle;
				line-height: 14px;
				margin-left: 10px;
				font-style: normal;

				.menu__link_open & {
					transform: rotate(180deg);
					color: $yellow;
				}
			}
		}
	}

	&__submenu {
		display: none;
		background: $bg-dark;

		@media (min-width: 768px) {
			display: none!important;
		}
	}

	&__sublink {
		display: block;
		padding: 15px 25px 16px;
		@include link-inherit;
	}
}