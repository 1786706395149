.tabs {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border-bottom: 1px solid $bg-light;
	font-weight: 700;
	font-style: italic;
	font-size: 20px;

	&_theme_collapsed {
		@media (max-width: 767px) {
			display: block;
		}
	}

	&__inner {
		@media (min-width: 768px) {
			display: flex!important;
			flex-direction: row;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__item {
		height: 30px;
		padding: 0 15px;
		line-height: 30px;
		vertical-align: middle;
		@include skew;
		cursor: pointer;

		.tabs_theme_collapsed & {
			@media (max-width: 767px) {
				height: 50px;
				padding: 0 30px;
				line-height: 49px;
				vertical-align: middle;
				border-top: 1px solid $grey;
				@include no-skew;
			}
		}

		&_current {
			background-color: $yellow;
			cursor: default;

			.tabs_theme_collapsed & {
				@media (max-width: 767px) {
					position: relative;
					border-top: none;

					&::before {
						content: "";
						position: absolute;
						right: 0;
						bottom: -1px;
						left: 0;
						z-index: 2;
						height: 1px;
						background: $yellow;
					}
				}
			}
		}
	}

	&__text {
		display: block;
		@include skew-left;
		@include link-inherit;

		.tabs_theme_collapsed & {
			@media (max-width: 767px) {
				@include no-skew;
			}
		}
	}

	&__mobile {
		@media (min-width: 768px) {
			display: none;
		}

		.tabs_theme_collapsed & {
			border-top-color: #ffffff;
		}

		.tabs__text {
			position: relative;
			display: inline-block;
			vertical-align: middle;

			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				width: 2px;
				height: 12px;
				margin-top: -7px;
				background-color: $black;
			}

			&::before {
				right: -10px;
				@include skew;
			}

			&::after {
				right: -14px;
				@include skew-left;
			}
		}

		&_open {
			.tabs_theme_collapsed & {
				border-bottom-color: #ffffff;
			}

			.tabs__text {
				&::before,
				&::after {
					background-color: $yellow;
				}

				&::before {
					@include skew-left;
				}

				&::after {
					@include skew;
				}
			}
		}
	}

	&__container {
		display: none;

		&_open {
			display: block;
		}
	}
}