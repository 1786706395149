@import "utils/_variables";
@import "utils/_mixins";

@import "base/_base";

@import "components/_logo";
@import "components/_menu";
@import "components/_language";
@import "components/_button";
@import "components/_info-item";
@import "components/_page-nav";
@import "components/_tabs";
@import "components/_title";
@import "components/_pagination";
@import "components/_project-accent";
@import "components/_projects-carousel-block";

@import "layout/_header";
@import "layout/_footer";
@import "layout/_projects-block";
@import "layout/_news-block";

@import "pages/_home";
@import "pages/_job";
@import "pages/_interview";
@import "pages/_history";
@import "pages/_contacts";
@import "pages/_news";
@import "pages/_news-single";
@import "pages/_about";
@import "pages/_search";
@import "pages/_services";
@import "pages/_partners";
@import "pages/_projects";
@import "pages/_projects-categories";
@import "pages/_projects-single";