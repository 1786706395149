.mobile-news {
	@media (min-width: 768px) {
		display: none;
	}
}

.news-body {
	max-width: 634px;
	padding: 60px 0 65px;

	@media (max-width: 767px) {
		padding: 30px 0 30px;
		font-size: 14px;
	}

	&__title {
		margin-bottom: 40px;

		@media (max-width: 767px) {
			margin-bottom: 25px;
		}
	}

	&__text {
		p {
			margin-bottom: 25px;
		}
	}
}