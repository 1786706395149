.project-accent {
	position: relative;
	height: 100vh;
	min-height: 450px;
	max-height: 699px;
	color: $white;
	background-color: $bg-dark;

	@media (max-width: 575px) {
		height: calc(100vh - 100px);
		min-height: 334px;
		max-height: 500px;
	}

	&__picture {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: calc(50% + 270px);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		&_theme_light {
			width: 100%;
		}

		@media (max-width: 991px) {
			width: 100%;
		}
	}

	&__cover {
		position: absolute;
		top: 0;
		right: calc(50% + 140px);
		bottom: 0;
		left: 0;
		z-index: 2;
		@include skew;
		background-color: $bg-dark;

		@media (max-width: 991px) {
			right: 0;
			@include no-skew;
			opacity: 0.5;
		}

		&_theme_light {
			right: 0;
			@include no-skew;
			opacity: 0.5;
		}
	}

	&__content {
		height: 100%;
	}

	&__info {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;
		padding: 140px 0 50px;
		font-style: italic;

		@media (max-width: 767px) {
			padding: 0;
			justify-content: space-around;
		}
	}

	&__text {
		max-width: 334px;
		margin-bottom: auto;
		font-weight: 700;
		font-size: 30px;

		@media (max-width: 767px) {
			margin-bottom: 0;
		}

		@media (max-width: 575px) {
			max-width: 240px;
			font-size: 18px;
		}
	}

	&__place {
		margin-top: 15px;
		font-weight: 400;
		font-size: 21px;

		@media (max-width: 575px) {
			font-size: 14px;
		}
	}

}