.services {
	padding-top: 70px;
	padding-bottom: 140px;

	@media (max-width: 767px) {
		padding-top: 0;
		padding-bottom: 70px;
	}

	&__title {
		margin-bottom: 38px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			margin-bottom: 15px;
			text-transform: none;
		}
	}

	&__category {
		padding-top: 20px;
		border-top: 1px solid $bg-light;

		@media (max-width: 767px) {
			padding-top: 0;
			border-top: none;
		}
	}

	&__category-name {
		display: block;
		@include link-inherit;

		&_device_desktop {
			position: relative;
			margin-bottom: 17px;
			cursor: pointer;

			@media (max-width: 767px) {
				display: none;
			}

			&::after {
				content: "V";
				position: absolute;
				top: 0;
				right: 0;
				font-style: normal;
				font-size: 25px;
				color: $bg-light;
				transition: all 0.3s ease;
			}
		}

		&_device_mobile {
			display: block;
			padding: 12px 0 12px 30px;
			@include link-inherit;
			border-top: 1px solid $bg-light;
		}

		&_open {
			&::after {
				transform: rotate(180deg);
				color: $yellow;
				transition: all 0.3s ease;
			}

			@media (max-width: 767px) {
				position: relative;
				background-color: $yellow;
				border-top: none;

				&::before {
					content: "";
					position: absolute;
					right: 0;
					bottom: -1px;
					left: 0;
					z-index: 2;
					height: 1px;
					background-color: $yellow;
				}
			}
		}
	}

	&__list {
		padding: 45px 0 38px;

		@media (max-width: 767px) {
			padding: 20px 0;
		}
	}

	&__item {
		position: relative;
		margin-bottom: 25px;
		padding-top: 15px;
		padding-left: 50px;

		@media (max-width: 767px) {
			padding-left: 30px;
			font-size: 18px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 35px;
			width: 4px;
			height: 20px;
			background-color: $bg-light;
			@include skew;

			@media (max-width: 767px) {
				left: 19px;
			}
		}

		ul {
			padding-top: 30px;

			@media (max-width: 767px) {
				padding-top: 15px;
			}
		}

		li {
			margin-bottom: 20px;
			padding-left: 15px;
			font-style: italic;
			font-size: 18px;

			@media (max-width: 767px) {
				margin-bottom: 15px;
				padding-left: 0;
				font-style: normal;

				&::before {
					content: "— "
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__mobile {
		margin-bottom: 40px;
		font-weight: 700;
		font-style: italic;
		font-size: 18px;
		border-bottom: 1px solid $bg-light;

		@media (min-width: 768px) {
			display: none;
		}
	}

	&__mobile-opener {
		height: 50px;
		padding: 16px 30px 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: baseline;
		width: 100%;
		border-top: none;
	}

	&__mobile-text {
		position: relative;
		display: inline-block;
		line-height: 1;
		vertical-align: middle;

		.services__mobile-opener_open & {
			&::before,
			&::after {
				background-color: $yellow;
			}

			&::before {
				@include skew-left;
			}

			&::after {
				@include skew;
			}
		}
	}

	&__mobile-buttons {
		display: none;
	}
}