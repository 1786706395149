.contacts {
	position: relative;
	height: calc(100vh - 50px);
	min-height: 450px;
	overflow: hidden;

	@media (max-width: 991px) {
		max-height: 700px;
	}

	@media (max-width: 767px) {
		height: auto;
		min-height: 0;
		max-height: none;
	}

	&__tabs {
		position: absolute;
		top: 48px;
		left: 0;
		z-index: 3;
		padding-left: calc(50% - 534px);

		@media (max-width: 1199px) {
			padding-left: 30px;
		}

		@media (max-width: 767px) {
			position: static;
			top: auto;
			left: auto;
			padding-left: 0;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding-top: 50px;
		padding-bottom: 50px;

		@media (max-width: 767px) {
			display: block;
			padding-top: 30px;
			padding-bottom: 30px;
			height: auto;
		}
	}

	&__title {
		margin-bottom: 50px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			margin-bottom: 17px;
			text-transform: none;
		}
	}

	&__text {
		max-width: 350px;
		font-weight: 700;
		font-style: italic;
		font-size: 25px;

		@media (max-width: 575px) {
			font-weight: 400;
			font-style: normal;
			font-size: 18px;
		}
	}

	&__map {
		position: absolute;
		right: 0;
		bottom: 0;
		left: calc(50% - 324px);
		height: 100%;
		overflow: hidden;

		@media (max-width: 1199px) {
			left: 270px;
		}

		@media (max-width: 767px) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			width: 100%;
			height: 360px;
		}
	}

	&__map-inner {
		width: 100%;
		height: 100%;
	}

	&__desktop-mail {
		@media (max-width: 575px) {
			display: none;
		}
	}

	&__mobile-mail {
		padding-top: 50px;
		padding-bottom: 70px;
		font-weight: 700;
		font-style: italic;
		background-color: $bg-light;

		@media (min-width: 576px) {
			display: none;
		}
	}

	&__skew {
		position: absolute;
		top: 0;
		width: calc(50% - 124px);
		bottom: 0;
		left: 0;
		z-index: 2;
		background-color: #ffffff;
		@include skew;

		@media (max-width: 1199px) {
			width: 400px;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__inner {
		position: relative;
		z-index: 3;
		max-width: 400px;

	}
}