.title {
	font-weight: 700;
	font-style: italic;

	&_size_big {
		font-size: 40px;

		@media (max-width: 575px) {
			font-size: 30px;
		}
	}

	&_size_medium {
		font-size: 30px;

		@media (max-width: 575px) {
			font-size: 25px;
		}
	}

	&_size_small {
		font-size: 25px;

		@media (max-width: 575px) {
			font-size: 22px;
		}
	}

	&_case_uppercase {
		text-transform: uppercase;

		@media (max-width: 767px) {
			text-transform: none;
		}
	}
}