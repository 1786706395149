.job {
	&__title {
		margin-bottom: 35px;
	}

	&__article {
		padding-top: 30px;
	}

	&__heading {
		margin-bottom: 50px;

		@media (max-width: 767px) {
			margin-bottom: 30px;
		}
	}

	&__description {
		max-width: 550px;
		margin-bottom: 55px;

		@media (max-width: 575px) {
			font-size: 16px;
		}
	}

	&__subtitle {
		margin-bottom: 65px;

		@media (max-width: 575px) {
			position: relative;
			margin-bottom: 30px;
			padding-left: 25px;

			&::before {
				@include accent;
				background-color: $grey;
			}
		}
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@media (max-width: 767px) {
			display: block;
		}

		@media (max-width: 575px) {
			margin-bottom: 50px;
		}
	}

	&__item {
		position: relative;
		width: 50%;
		min-height: 106px;
		margin-bottom: 40px;
		padding-left: 25px;

		@media (max-width: 767px) {
			width: auto;
			min-height: 0;
		}

		@media (max-width: 575px) {
			margin-bottom: 25px;
			font-size: 16px;
		}

		&:nth-child(odd) {
			padding-right: 30px;

			@media (max-width: 767px) {
				padding-right: 0;
			}
		}

		&::before {
			@include accent;
			background-color: $grey;

			@media (max-width: 575px) {
				content: "— ";
				position: static;
				top: auto;
				left: auto;
				width: auto;
				height: auto;
				@include no-skew;
				background-color: transparent;
			}
		}
	}

	&__footer {
		padding: 100px 0 140px;
		background-color: $bg-light;

		@media (max-width: 767px) {
			padding: 50px 0 70px;
		}
	}

	.collapsing {
		-webkit-transition: none;
		transition: none;
	}
}
