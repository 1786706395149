.news {
	padding-top: 46px;
	padding-bottom: 70px;

	@media (max-width: 767px) {
		padding-top: 0;
	}

	&__all-news {
		margin-bottom: 90px;
		margin-left: -30px;

		@media (max-width: 1199px) {
			margin-bottom: 50px;
			margin-left: 0;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__title {
		margin-bottom: 20px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			text-transform: none;
		}

		&_visible_desktop {
			@media (max-width: 767px) {
				display: none;
			}
		}

		&_visible_mobile {
			@media (min-width: 768px) {
				display: none;
			}
		}
	}

	&__tabs {
		margin-bottom: 55px;

		@media (max-width: 767px) {
			margin-bottom: 31px;
			margin-right: -30px;
			margin-left: -30px;
		}
	}

	&__row {
		margin-bottom: 35px;
	}

	&__item {
		margin-bottom: 50px;
	}
}