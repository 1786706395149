.partners {
	&__title {
		margin-bottom: 48px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			margin-bottom: 25px;
			text-transform: none;
		}
	}

	&__list {
		padding-top: 45px;
		border-top: 1px solid $bg-light;

		@media (max-width: 767px) {
			padding-top: 15px;
			border-top: none;
		}
	}

	&__item {
		margin-bottom: 45px;
		padding-top: 20px;
		padding-bottom: 35px;
		padding-left: 23px;
		border-bottom: 1px solid $bg-light;

		@media (max-width: 767px) {
			margin: 0 -30px 30px;
			padding: 20px 15px 35px 38px;
		}
	}

	&__logo {
		position: relative;

		@media (max-width: 767px) {
			margin-bottom: 30px;
		}

		&::before {
			content: "";
			position: absolute;
			top: -16px;
			left: -5px;
			width: 4px;
			height: 20px;
			background: $yellow;
			@include skew;

			@media (max-width: 767px) {
				display: none;
			}
		}
	}

	&__img {
		max-width: 100%;
	}

	&__info {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: -16px;
			left: -10px;
			width: 4px;
			height: 20px;
			background: $bg-light;
			@include skew;

			@media (max-width: 575px) {
				left: -3px;
			}
		}
	}

	&__name {
		display: block;
		margin-bottom: 15px;
		color: inherit;
		font-weight: 700;
		font-style: italic;
		font-size: 24px;

		&:hover {
			color: inherit;
		}

		@media (max-width: 575px) {
			font-size: 14px;
		}
	}

	&__description {
		font-style: italic;

		@media (max-width: 575px) {
			font-size: 14px;
		}
	}
}