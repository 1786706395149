.info-item {

	@media (max-width: 575px) {
		margin-right: auto;
		margin-left: auto;
		max-width: 336px;
	}

	&__top {
		position: relative;
		display: block;
		margin-bottom: 17px;
		padding-top: 58.33333333%;
	}

	&__cover {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;


		&_theme_dark {
			background: rgba(0, 0, 0, 0.7);

			@media (max-width: 575px) {
				background: rgba(0, 0, 0, 0.3);
			}

			&:hover {
				background: transparent;
			}
		}

		&:hover {
			.info-item__button {
				display: inline-block;
			}
		}
	}

	&__button {
		display: none;
	}

	&__img-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	&__date {
		margin-bottom: 3px;
		font-style: italic;
		color: $lightgrey;
	}

	&__text {
		font-size: 21px;
		@include link-inherit;

		&_size_s {
			@media (max-width: 575px) {
				font-size: 16px;
			}
		}
	}
}