@mixin link-white {
	color: $white;

	&:link,
	&:hover,
	&:active,
	&:visited {
		color: $white;
	}
}

@mixin link-white-hovered {
	color: $white;

	// &:link,
	// &:active,
	// &:visited {
	// 	color: $white;
	// }

	&:hover {
		color: $yellow;
	}

	@media (max-width: 991px) {
		&:hover {
			color: $white;
		}
	}
}

@mixin link-inherit {
	color: inherit;

	&:link,
	&:hover,
	&:active,
	&:visited {
		color: inherit;
	}
}

@mixin skew {
	transform: skewX(-20deg);
}

@mixin skew-left {
	transform: skewX(20deg);
}

@mixin no-skew {
	transform: none;
}

@mixin accent {
	content: "";
	position: absolute;
	top: -15px;
	left: 5px;
	width: 5px;
	height: 23px;
	@include skew;
}