.projects-single {

	&__title {
		margin-bottom: 35px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			text-transform: none;
		}

		@media (max-width: 575px) {
			margin-bottom: 20px;
			font-size: 22px;
		}
	}

	&__place {
		margin-bottom: 45px;
		font-style: italic;
		font-size: 20px;

		@media (max-width: 575px) {
			margin-bottom: 30px;
			font-size: 14px;
		}
	}

	&__info {
		padding-top: 85px;
		padding-bottom: 100px;

		@media (max-width: 991px) {
			padding-top: 50px;
			padding-bottom: 70px;
		}

		@media (max-width: 575px) {
			padding-top: 45px;
			padding-bottom: 60px;
			font-size: 14px;
		}
	}

	&__subtitle {
		margin-bottom: 30px;

		@media (max-width: 575px) {
			margin-bottom: 20px;
		}
	}

	&__list {
		columns: 2;

		@media (max-width: 767px) {
			columns: auto;
		}
	}

	&__item {
		position: relative;
		margin-bottom: 20px;
		padding: 15px 25px 0;
		-webkit-column-break-inside: avoid;
    	page-break-inside: avoid;
    	break-inside: avoid;

		@media (max-width: 575px) {
			margin-bottom: 15px;
			padding-left: 20px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 5px;
			width: 4px;
			height: 20px;
			background: $bg-light;
			@include skew;
		}
	}

	&__description {
		columns: 2;

		@media (max-width: 767px) {
			columns: auto;
		}

		ul {
			margin-bottom: 25px;
			padding-left: 10px;
		}

		li {
			position: relative;
			padding-left: 15px;

			&::before {
				content: "";
				position: absolute;
				top: 9px;
				left: 0;
				width: 5px;
				height: 5px;
				background-color: $yellow;
				@include skew;
			}
		}
	}
}