.projects-block {
	position: relative;
	min-height: 700px;
	color: $white;
	background-color: $bg-dark;
	overflow: hidden;

	@media (max-width: 1199px) {
		min-height: 500px;
	}

	@media (max-width: 991px) {
		color: $black;
		background-color: transparent;
	}

	&_height_tall {
		height: calc(100vh - 50px);
		min-height: 450px;
		max-height: 770px;

		@media (max-width: 991px) {
			height: auto;
			min-height: 0;
			max-height: none;
		}
	}

	&__news {
		position: absolute;
		top: 45px;
		left: 5.7%;
		z-index: 4;

		@media (max-width: 1199px) {
			left: 30px;
		}

		@media (max-width: 991px) {
			position: static;
			top: auto;
			left: auto;
			margin-bottom: 50px;
			padding-top: 30px;
			padding-left: 30px;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__cover {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
		width: calc(50% - 136px);
		background-color: $bg-dark;
		@include skew;

		@media (max-width: 991px) {
			display: none;
		}
	}

	&__content {
		height: 100%;

		@media (max-width: 991px) {
			height: auto;
		}
	}

	&__info {
		position: relative;
		z-index: 3;
		max-width: 320px;
		min-height: 100%;
		padding-top: 100px;
		padding-bottom: 200px;

		.projects-block_height_tall & {
			padding-top: 150px;

			@media (max-width: 991px) {
				margin-bottom: 40px;
				padding-top: 0;
			}
		}

		@media (max-width: 1199px) {
			padding-top: 50px;
			max-width: 270px;
			min-height: 0;
		}

		@media (max-width: 991px) {
			max-width: none;
			padding-top: 30px;
			padding-bottom: 0;
		}
	}

	&__heading {
		margin-bottom: 40px;

		@media (max-width: 991px) {
			margin-bottom: 30px;
		}
	}

	&__title {
		@media (max-width: 991px) {
			margin-bottom: 30px;
		}
	}

	&__text {
		font-style: italic;
		font-size: 21px;

		@media (max-width: 575px) {
			font-size: 14px;
		}
	}

	&__carousel {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		width: calc(50% + 265px);
		overflow: hidden;

		@media (max-width: 991px) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			width: 100%;
			height: 500px;
		}

		@media (max-width: 480px) {
			height: 480px;
		}

		@media screen and (max-width: 991px) and (orientation: landscape) {
			height: 100vh;
		}

		.swiper-fullscreen & {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 5;
			width: auto;
			background-color: $bg-dark;
		}
	}

	&__inner {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;

		.swiper-fullscreen & {
			left: 0;
		}
	}

	&__swiper-container {
		height: 100%;
	}

	&__slide {
		overflow: hidden;
	}

	&__img {
		width: 100%;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 1199px) {
			background-size: cover;
		}

		&_greyscale_color {
			display: none;

			@media (max-width: 991px) {
				display: block;
			}

			.swiper-fullscreen & {
				display: block;
			}
		}

		&_greyscale_grey {

			@media (max-width: 991px) {
				display: none;
			}

			.swiper-fullscreen & {
				display: none;
			}
		}
	}

	&__pagination {
		position: absolute;
		bottom: 42px;
		right: -105px;
	}

	&__page {
		display: block;
		width: auto;
		height: 21px;
		margin-bottom: 12px;
		border-radius: 0;

		&_active {

			.projects-block__bullet {
				background-color: $yellow;
			}

			.projects-block__fraction {
				display: inline-block;
			}
		}
	}

	&__bullet {
		display: inline-block;
		width: 4px;
		height: 21px;
		background: $bg-light;
		opacity: 1;
	}

	&__fraction {
		display: none;
		margin-left: 15px;
		padding-top: 1px;
		font-weight: 700;
		font-style: italic;
		font-size: 16px;
		vertical-align: top;
		color: $bg-light;
		@include skew-left;
	}

	&__navigation {
		position: absolute;
		right: 100px;
		bottom: 45px;
		z-index: 6;
		@include skew;

		@media (max-width: 1199px) {
			right: 30px;
		}

		@media (max-width: 991px) {
			position: relative;
			right: auto;
			bottom: auto;
			display: flex;
			justify-content: space-between;
			margin-top: -50px;
			background: rgba(41, 41, 41, 0.9);
			@include no-skew;
		}

		.swiper-fullscreen & {
			position: fixed;
			right: 60px;
			bottom: 32px;
		}
	}

	&__nav-el {
		display: inline-block;
		vertical-align: top;
		width: 40px;
		height: 36px;
		margin-right: 8px;
		font-weight: 700;
		font-style: italic;
		font-size: 19px;
		line-height: 36px;
		text-align: center;
		vertical-align: middle;
		color: $black;
		background-color: $bg-light;
		cursor: pointer;

		@media (max-width: 991px) {
			height: 50px;
			line-height: 49px;
			color: $bg-light;
			background-color: transparent;
		}

		&:hover {
			background-color: $yellow;

			@media (max-width: 991px) {
				background-color: transparent;
			}
		}
	}

	&__inner-text {
		display: inline-block;
		vertical-align: top;
		@include skew-left;

		@media (max-width: 991px) {
			@include no-skew;
		}
	}

	&__bottom {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		display: none;
		align-items: center;
		height: 100px;
		background-color: rgba(41, 41, 41, 0.9);

		.swiper-fullscreen & {
			display: flex;
		}
	}

	&__name {
		padding-right: 500px;
		font-weight: 700;
		font-style: italic;
		font-size: 20px;
		color: $white;
	}

	&__fullscreen {
		.swiper-fullscreen & {
			display: none;
		}

		@media (max-width: 991px) {
			display: none;
		}
	}

	&__hide-fullscreen {
		position: fixed;
		top: 40px;
		right: 60px;
		z-index: 5;
		display: none;
		@include skew;

		.swiper-fullscreen & {
			display: inline-block;
		}
	}

	&__fraction-bottom {
		position: fixed;
		right: 225px;
		bottom: 35px;
		z-index: 5;
		display: none;
		font-weight: 700;
		font-style: italic;
		color: $bg-light;

		.swiper-fullscreen & {
			display: block;
		}
	}

	&__fraction-mobile {
		@media (min-width: 992px) {
			display: none;
		}
	}

	&__landscape {
		padding-top: 15px;
		font-style: italic;
		font-size: 14px;
		color: $bg-grey-alt;

		@media (min-width: 992px) {
			display: none;
		}

		@media screen and (max-width: 991px) and (orientation: landscape) {
			display: none;
		}
	}
}