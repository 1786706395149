.search {
	padding-top: 45px;
	padding-bottom: 115px;

	&__form {
		display: flex;
		flex-direction: row;
		width: 600px;
		max-width: 100%;
		height: 25px;
		margin: 0 auto 115px;

		@media (max-width: 767px) {
			margin-bottom: 50px;
		}
	}

	&__input-wrapper {
		flex: 1 0 auto;
		border: 1px solid $bg-light;
		@include skew;
		overflow: hidden;
	}

	&__input {
		display: block;
		width: 100%;
		height: 100%;
		height: 23px;
		padding-left: 10px;
		border: none;
		font-style: italic;
		font-size: 14px;
		@include skew-left;
		outline: none;
	}

	&__button {
		flex: 0 0 auto;
		width: 43px;
		height: 100%;
		background: $black;
		border: none;
		@include skew;
		cursor: pointer;
	}

	&__button-inner {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		@include skew-left;

		&:hover {
			.search__svg-search {
				fill: $yellow;
			}
		}
	}

	&__svg-wrapper {
		display: block;
		width: 15px;
		height: 21px;
	}

	&__svg-search {
		transform: scale(0.9);
		width: 15px;
		height: 21px;
		fill: $white;
	}

	&__title {
		margin-bottom: 70px;

		@media (max-width: 767px) {
			margin-bottom: 35px;
		}
	}

	&__results {
		padding-top: 17px;
		border-top: 1px solid $bg-light;
	}

	&__result {
		position: relative;
		margin-bottom: 35px;
		padding: 20px 0 0 18px;

		@media (max-width: 767px) {
			font-size: 18px;
		}

		@media (max-width: 575px) {
			font-size: 16px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 4px;
			height: 23px;
			background-color: $bg-light;
			@include skew;
		}
	}

	&__article {
		display: block;
		margin-bottom: 2px;
		font-weight: 700;
		font-style: italic;
		font-size: 30px;
		@include link-inherit;

		@media (max-width: 767px) {
			font-size: 25px;
		}

		@media (max-width: 575px) {
			font-size: 20px;
		}
	}

	&__match {
		font-weight: 700;
	}

	&__pagination {
		margin-top: 140px;

		@media (max-width: 767px) {
			margin-top: 70px;
		}
	}
}