.logo {
	font-size: 15.9px;
	@include link-white;

	&__text {
		display: inline-block;
		vertical-align: bottom;
		margin-left: 3px;
		@media (max-width: 991px) {
			display: none;
		}
	}
}