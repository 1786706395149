.button {
	display: inline-block;
	min-width: 100px;
	height: 38px;
	padding: 0 23px 0 20px;
	font-weight: 700;
	font-style: italic;
	font-size: 19px;
	line-height: 37px;
	text-align: center;
	vertical-align: middle;
	color: $black;
	@include skew;

	&:hover,
	&:focus,
	&:active,
	&:visited,
	&:link {
		color: $black;
	}

	&__inner {
		display: block;
		padding-top: 1px;
		@include skew-left;
	}

	&_color_yellow {
		background: $yellow;
	}

	&_color_grey {
		background: $grey;

		&:hover {
			background: $yellow;
		}
	}

	&_skew_no {
		transform: none;
	}
}