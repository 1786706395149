.projects {
	padding-top: 95px;

	@media (max-width: 767px) {
		padding-top: 40px;
	}

	&__title {
		margin-bottom: 35px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			text-transform: none;
		}

		@media (max-width: 400px) {
			font-size: 28px;
		}
	}

	&__description {
		margin-bottom: 50px;
		max-width: 800px;
		font-style: italic;
		font-size: 20px;

		@media (max-width: 767px) {
			font-size: 16px;
		}
	}

	&__category {
		margin-bottom: 125px;

		@media (max-width: 640px) {
			margin-bottom: 60px;
		}

		@media (max-width: 400px) {
			margin-bottom: 40px;
		}
	}

}