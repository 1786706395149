.projects-carousel-block {

	&_theme_grey {
		padding-top: 62px;
		padding-bottom: 45px;
		background-color: $bg-light;
	}

	&__top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 35px;

		@media (max-width: 767px) {
			display: block;
		}
	}

	&__title {
		margin-right: 60px;
		font-weight: 700;
		font-style: italic;
		font-size: 30px;

		@media (max-width: 767px) {
			margin-right: 0;
			margin-bottom: 30px;
			font-size: 25px;
		}

		@media (max-width: 575px) {
			font-size: 20px;
		}
	}

	&__link {
		flex: 0 0 auto;
		@media (max-width: 767px) {
			background: $yellow;
		}

		&_device_desktop {
			@media (max-width: 767px) {
				display: none;
			}
		}

		&_device_mobile {
			@media (min-width: 768px) {
				display: none;
			}
		}
	}

	&__link-wrapper {
		text-align: center;

		@media (max-width: 767px) and (min-width: 641px) {
			margin-top: 50px;
		}
	}

	&__carousel {
		position: relative;

		@media (max-width: 1199px) {
			padding: 0 20px;
		}

		@media (max-width: 640px) {
			width: 374px;
			max-width: 100%;
			margin: 0 auto;
		}

		@media (max-width: 400px) {
			padding: 0 10px;
		}
	}

	&__control {
		position: absolute;
		top: 75px;
		width: 40px;
		height: 36px;
		font-weight: 700;
		font-style: italic;
		font-size: 20px;
		line-height: 36px;
		text-align: center;
		vertical-align: middle;
		@include skew;
		cursor: pointer;

		@media (max-width: 1199px) {
			top: 0;
			width: 25px;
			margin-top: 7%;
			@include no-skew;
		}

		@media (max-width: 767px) {
			margin-top: 10%;
		}

		@media (max-width: 640px) {
			margin-top: 20%;
		}

		&_theme_grey {
			@media (max-width: 640px) {
				display: none;
			}
		}

		&:hover {
			background-color: $yellow;

			@media (max-width: 1199px) {
				background-color: transparent;
			}
		}

		&_prev {
			left: -66px;

			@media (max-width: 1199px) {
				left: -20px;
			}
		}

		&_next {
			right: -66px;

			@media (max-width: 1199px) {
				right: -20px;
			}
		}
	}

	&__inner-text {
		display: block;
		@include skew-left;

		@media (max-width: 1199px) {
			@include no-skew;
		}
	}

	&__swiper-wrapper {
		@media (max-width: 640px) {
			display: block;
		}
	}

	&__slide {
		@media (max-width: 640px) {
			margin-bottom: 50px;

			&:nth-child(n+4) {
				display: none;
			}
		}
	}
}