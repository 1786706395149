.start {
	position: relative;
	height: calc(100vh - 50px);
	max-height: 899px;
	color: $white;
	background: $bg-dark;
	overflow: hidden;

	@media (max-width: 1199px) {
		height: 600px;
	}

	@media (max-width: 991px) {
		height: 500px;
	}

	@media (max-width: 575px) {
		height: 470px;
	}

	&__wrapper {
		position: absolute;
		top: -1px;
		right: -11%;
		bottom: 0;
		left: -11%;

		@media (max-width: 1199px) {
			left: -12%
		}

		@media (max-width: 991px) {
			top: 0;
			right: 0;
			left: 0;
		}
	}

	&__pseudo-project {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 30%;
		background-size: auto 100%;

		@media (max-width: 991px) {
			display: none;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $bg-dark;
		}
	}

	&__carousel {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		margin: 0 auto;
		overflow: hidden;
		animation: projects 1s ease 1.25s;
		animation-fill-mode: both;
		@include skew;

		@media (max-width: 1199px) {
			animation: none;
		}

		@media (max-width: 991px) {
			display: block;
			@include no-skew;
		}
	}

	@keyframes projects {
		from {
			width: 20px;
			opacity: 0;
		}

		10% {
			width: 20px;
			opacity: 1;
		}

		to {
			width: 100%;
			opacity: 1;
		}
	}

	@keyframes display {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	&__main {
		width: 37%;
		height: 100%;
		padding: 190px 0 0 16%;
		font-weight: 700;
		font-style: italic;
		font-size: 30px;
		background-color: $bg-dark;

		@media (max-width: 1366px) {
			width: 32%;
		}

		@media (max-width: 1199px) {
			padding-left: 30px;
		}

		@media (max-width: 991px) {
			display: none;
		}
	}

	&__info {
		position: relative;
		z-index: 4;
		width: 33%;
		height: 100%;
		padding: 190px 0 0 8%;
		font-weight: 700;
		font-style: italic;
		font-size: 30px;
		animation: display 0.6s ease 2s;
		animation-fill-mode: both;

		@media (max-width: 1366px) {
			padding-top: 80px;
			padding-left: 30px;
		}

		@media (max-width: 1199px) {
			animation: none;
		}

		@media (max-width: 991px) {
			display: none;
		}
	}

	&__main-inner {
		height: 100%;
		@include skew-left;
	}

	&__text {
		max-width: 234px;
	}

	&__about {
		position: absolute;
		bottom: 12%;
		left: 8%;
		z-index: 5;
		animation: display 0.6s ease 2s;
		animation-fill-mode: both;

		@media (max-width: 1366px) {
			left: 30px;
		}

		@media (max-width: 1199px) {
			animation: none;
		}

		@media (max-width: 991px) {
			display: none;
		}
	}

	&__oblique {
		margin-right: 40px;
		width: 63%;
		flex: 0 0 auto;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		height: 100%;

		@media (max-width: 1600px) {
			margin-right: 20px;
		}

		@media (max-width: 1366px) {
			width: 68%;
		}

		@media (max-width: 1199px) {
			margin-right: 0;
		}

		@media (max-width: 991px) {
			width: 100%;
		}
	}

	&__swiper {
		width: 100%;
		background: $bg-dark;
	}

	&__column {
		position: relative;
		z-index: 2;
		flex: 0 0 auto;
		width: 30%;
		margin-left: -1px;
		padding: 235px 15px 0 30px;
		overflow: hidden;
		background-position: center top;
		background-repeat: no-repeat;
		background-size: auto 100%;
		transition: width 0.6s ease;
		cursor: pointer;

		@media (max-width: 991px) {
			width: auto;
			height: 100%;
			margin-left: 0;
			padding-top: 110px;
			padding-left: 45px;
			font-style: italic;
		}

		@media (max-width: 575px) {
			height: 470px;
			padding-top: 70px;
			padding-right: 30px;
		}

		&_mobile {
			@media (min-width: 992px) {
				display: none;
			}

			@media (max-width: 575px) {
				background: url("../img/start-column-mobile.jpg") no-repeat;
				background-size: cover;
			}

			&::after {
				display: none;
			}
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;
			background: rgba(0, 0, 0, 0.5);
			opacity: 1;
			transition: opacity 0.6s ease;
		}

		&:hover {
			width: 35%;
			transition: width 0.6s ease;

			&::after {
				opacity: 0;
				transition: opacity 0.6s ease;

				@media (max-width: 991px) {
					opacity: 1;
				}
			}

			.start__link {
				opacity: 1;
				transition: opacity 0.3s ease;
			}
		}

		&:nth-child(n + 5) {
			display: none;

			@media (max-width: 991px) {
				display: block;
			}
		}
	}

	&__img {
		position: absolute;
		top: 0;
		right: -17vh;
		bottom: 0;
		left: -17vh;
		@include skew-left;

		@media (max-width: 991px) {
			right: 0;
			left: 0;
			background-size: cover;
			@include no-skew;
		}
	}

	.swiper-wrapper {
		align-items: stretch;
	}

	&__project {
		position: relative;
		z-index: 3;
		width: 260px;
		font-size: 19px;

		@media (max-width: 1440px) {
			width: 220px;
		}

		@media (max-width: 1199px) {
			width: 200px;
			font-size: 17px;
		}

		@media (max-width: 991px) {
			height: 100%;
			width: 260px;
			max-width: 100%;
			font-size: 19px;
		}

		@media (max-width: 575px) {
			font-size: 14px;
		}

		&_main {
			font-weight: 700;
			font-size: 25px;

			&::before {
				display: none;
			}
		}

		&::before {
			content: "";
			position: absolute;
			top: -40px;
			left: 0;
			width: 5px;
			height: 20px;
			background-color: $yellow;

			@media (max-width: 991px) {
				top: -12px;
				left: -14px;
				width: 4px;
				@include skew;
			}
		}
	}

	&__number {
		display: block;
		margin: 15px 0 10px;
		font-size: 37px;

		@media (max-width: 991px) {
			margin-top: 40px;
			font-weight: 700;
		}

		@media (max-width: 575px) {
			margin-top: 30px;
			margin-bottom: 0;
		}
	}

	&__link-wrapper {
		padding-top: 30px;
		text-align: center;
		@include skew-left;

		@media (max-width: 991px) {
			position: absolute;
			bottom: 95px;
			margin-left: -6px;
			text-align: left;
			@include no-skew;
		}
	}

	&__link {
		opacity: 0;
		transition: opacity 0.3s ease;

		@media (max-width: 991px) {
			opacity: 1;
		}
	}

	&__all-projects {
		position: absolute;
		z-index: 3;
		right: 60px;
		bottom: 12%;
		animation: display 0.6s ease 2s;
		animation-fill-mode: both;

		@media (max-width: 1199px) {
			animation: none;
		}

		@media (max-width: 991px) {
			display: none;
		}
	}

	&__animated-wrapper {
		position: absolute;
		top: 0;
		right: -50%;
		bottom: 0;
		left: -50%;
		background-color: $bg-grey;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		animation: animatedbg 0.1s ease 2.25s;
		animation-fill-mode: both;

		@media (max-width: 1199px) {
			display: none;
		}
	}

	@keyframes animatedbg {
		from {
			background-color: $bg-grey;
		}

		to {
			background-color: $bg-dark;
		}
	}

	&__animated {
		@include skew;
		border: solid $yellow;
		border-width: 0 10px 0 10px;
		animation: yellow-line 2.2s ease-in;
		animation-fill-mode: both;
	}

	@keyframes yellow-line {
		from {
			width: 0;
			height: 100px;
			background: $yellow;
		}

		29% {
			background: $yellow;
		}

		30% {
			width: 0;
			height: 100%;
			background: transparent;
		}

		to {
			width: 100%;
		}
	}

	&__navigation {
		position: absolute;
		right: 20px;
		bottom: 20px;
		left: 20px;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		font-weight: 700;
		font-style: italic;
		color: $bg-light;
		font-size: 20px;

		@media (min-width: 991px) {
			display: none;
		}

		@media (max-width: 575px) {
			bottom: 32px;
		}
	}

	&__control {
		&_fraction {
			width: auto;
			margin-top: 2px;
			font-weight: 400;
		}
	}
}

.home-services {
	overflow: hidden;
	color: $white;
	background: $bg-grey;

	&__content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 110px;
		padding-bottom: 110px;

		@media (max-width: 1199px) {
			justify-content: space-around;
		}

		@media (max-width: 767px) {
			flex-direction: column;
			justify-content: flex-start;
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}

	&__info {
		width: 300px;
		max-width: 100%;
		margin-left: 30px;
		@include skew;

		@media (max-width: 1199px) {
			margin-left: 60px;
		}

		@media (max-width: 767px) {
			width: auto;
			margin-left: 0;
			margin-bottom: 30px;
			@include no-skew;
			font-style: italic;
		}
	}

	&__heading {
		margin-bottom: 30px;
		font-weight: 700;
		font-size: 30px;
	}

	&__item {
		margin-bottom: 20px;
		font-size: 19px;
		line-height: 1;

		@media (max-width: 767px) {
			margin-bottom: 12px;
		}

		&::before {
			content: "– ";
			position: absolute;
			padding-right: .25em;
			transform: translateX(-100%);

			@media (min-width: 768px) {
				display: none;
			}
		}
	}

	&__projects {
		font-style: italic;
		text-align: right;
		@include skew;

		@media (max-width: 1199px) {
			margin-right: 60px;
		}

		@media (max-width: 767px) {
			align-self: center;
			margin-right: 0;
			text-align: left;
			@include no-skew;
		}
	}

	&__number {
		margin-bottom: 20px;
		font-weight: 700;
		font-size: 300px;
		line-height: 0.75;
		@include skew-left;

		@media (max-width: 1199px) {
			font-size: 20vw;
		}

		@media (max-width: 860px) {
			font-size: 15vw;
		}

		@media (max-width: 767px) {
			font-size: 135px;
			@include no-skew;
		}
	}

	&__description {
		font-size: 20px;
		line-height: 1
	}

	&__text {
		display: block;
		@include skew-left;

		@media (max-width: 767px) {
			@include no-skew;
			margin-left: 70px;
		}
	}
}

.home-problems {
	margin: -1px 0;
	color: $white;
	background: url("../img/home-bg.jpg") center center no-repeat;
	background-size: cover;

	@media (max-width: 640px) {
		background-image: url("../img/home-bg-mobile.jpg");
	}

	&__content {
		position: relative;
		padding-top: 49.21875%;

		@media (max-width: 767px) {
			padding-top: 450px;
		}
	}

	&__wrapper {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__inner {
		flex: 1 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 17.46%;
		font-weight: 700;
		font-style: italic;
		font-size: 3.90625vw;
		line-height: 0.7;

		@media (max-width: 640px) {
			height: 50px;
			font-size: 22px;
		}
	}

	&__text {
		&:first-child {
			align-self: flex-start;
		}

		&:last-child {
			align-self: flex-end;
		}
	}

	&__divider {
		width: 1.953125%;
		margin: 0 2.34375%;
		align-self: stretch;
		background-color: $yellow;
		@include skew;

		@media (max-width: 767px) {
			width: 11px;
			height: 50px;
			margin: 0 12px;
		}
	}
}
