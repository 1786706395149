.projects-categories {
	&__title {
		margin-bottom: 35px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			text-transform: none;
		}

		@media (max-width: 575px) {
			display: none;
		}
	}

	&__description {
		max-width: 800px;
		margin-bottom: 50px;
		font-style: italic;
		font-size: 20px;

		@media (max-width: 575px) {
			display: none;
		}
	}

	&__project-accent {
		margin-bottom: 90px;

		@media (max-width: 575px) {
			margin-bottom: 45px;
		}
	}

	&__subtitle {
		margin-bottom: 40px;
	}

	&__row {
		margin-bottom: 35px;
	}

	&__item {
		margin-bottom: 50px;
	}

	&__pagination {
		margin-bottom: 90px;
	}

	&__bottom-carousel {
		@media (max-width: 640px) {
			display: none;
		}
	}

	&__page-nav {
		@media (max-width: 575px) {
			margin-bottom: 0;
		}
	}
}