@font-face {
  font-family: 'Helios';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/HeliosCond.woff") format("woff");
}

@font-face {
  font-family: 'Helios';
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/HeliosCondItalic.woff") format("woff");
}

@font-face {
  font-family: 'Helios';
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/HeliosCondBoldItalic.woff") format("woff");
}

html {
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Helios", "Arial", sans-serif;
	font-size: 22px;
	line-height: 1.25;
	color: $black;
}

.swiper-fullscreen {
	overflow: hidden;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

a,
a:link,
a:hover,
a:active,
a:visited {
	text-decoration: none;
}

[href^="mailto"],
[href^="tel"] {
	color: inherit;

	&:link,
	&:hover,
	&:active,
	&:visited {
		color: inherit;
	}
}

button,
input,
textarea {
	font-family: inherit;
}

.wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%;
}

main {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-negative: 0;
	    flex-shrink: 0;
	-ms-flex-preferred-size: auto;
	    flex-basis: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;

	@media (max-width: 767px) {
		padding-top: 50px;
	}
}

.content {
	width: 1200px;
	margin: 0 auto;
	padding: 0 66px;

	&_wide {
		width: 1566px;

		@media (max-width: 1565px) {
			width: 100%;
		}
	}

	@media (max-width: 1199px) {
		width: 100%;
	}

	@media (max-width: 1128px) {
		padding: 0 30px;
	}
}