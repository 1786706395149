.about {
	padding-bottom: 60px;

	&__history {
		max-width: 700px;
		margin-bottom: 67px;
		padding-left: 25px;
		font-style: italic;
		font-size: 24px;
		line-height: 1.2;

		@media (max-width: 767px) {
			padding-left: 0;
			font-size: 18px;
		}

		@media (max-width: 480px) {
			margin-bottom: 45px;
		}
	}

	&__item {
		display: flex;
		flex-direction: row;
		align-items: baseline;

		@media (max-width: 480px) {
			display: block;
			margin-bottom: 10px;
		}
	}

	&__date {
		flex: 0 0 auto;
		margin-right: 7px;

		@media (max-width: 480px) {
			margin-right: 0;
		}
	}

	&__year {
		font-weight: 700;
		color: $yellow;
	}

	&__title {
		margin-bottom: 35px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			text-transform: none;
		}
	}

	&__body {
		max-width: 940px;
		font-size: 18px;

		p {
			margin-bottom: 20px;
		}

		ul {
			margin-bottom: 25px;
			padding-left: 10px;
		}

		li {
			position: relative;
			padding-left: 15px;

			&::before {
				content: "";
				position: absolute;
				top: 9px;
				left: 0;
				width: 5px;
				height: 5px;
				background-color: $yellow;
				@include skew;
			}
		}
	}
}