.pagination {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	&__control {
		display: block;
		width: 40px;
		height: 36px;
		font-weight: 700;
		font-style: italic;
		font-size: 20px;
		line-height: 34px;
		vertical-align: middle;
		text-align: center;
		background-color: $yellow;
		@include skew;
		cursor: pointer;

		&_prev {
			margin-left: 10px;
		}

		&_next {
			margin-right: 10px;
		}
	}

	&__inner-text {
		display: block;
		@include skew-left;
		a {
			color: inherit;
		}
	}

	&__pages-desktop {
		@media (max-width: 767px) {
			display: none;
		}
	}

	&__page {
		display: inline-block;
		min-width: 40px;
		margin-right: 45px;
		vertical-align: top;

		&:last-child {
			margin-right: 0;
		}
	}

	&__page-link {
		position: relative;
		display: block;
		padding: 17px 0 0 12px;
		font-style: italic;
		font-size: 20px;
		color: $bg-light;

		&:hover,
		&:focus,
		&:active,
		&:link,
		&:visited {
			color: $bg-light;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 5px;
			width: 4px;
			height: 20px;
			background-color: $bg-light;
			@include skew;
		}

		&_current {
			padding: 28px 0 0 17px;
			color: $black;
			font-weight: 700;
			font-size: 36px;

			&:hover,
			&:focus,
			&:active,
			&:link,
			&:visited {
				color: $black;
			}

			&::before {
				width: 8px;
				height: 36px;
				background-color: $yellow;
			}
		}
	}

	&__pages-mobile {
		padding-top: 4px;
		font-style: italic;

		@media (min-width: 768px) {
			display: none;
		}
	}
}