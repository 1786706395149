.interview-start {
	position: relative;
	min-height: 757px;
	background-color: $bg-grey-alt;
	overflow: hidden;

	@media (max-width: 850px) {
		background-color: transparent;
	}

	&__news {
		position: absolute;
		top: 45px;
		left: 5.7%;

		@media (max-width: 1199px) {
			left: 30px;
		}

		@media (max-width: 850px) {
			position: relative;
			top: auto;
			left: auto;
			padding-top: 30px;
			padding-left: 30px;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: row;

		@media (max-width: 1199px) {
			display: block;
		}
	}

	&__info {
		flex: 0 0 auto;
		align-self: center;
		width: 434px;
		margin-right: 15px;
		padding: 110px 0;

		@media (max-width: 850px) {
			width: auto;
			margin-right: 0;
			padding: 50px 0;
		}

		@media (max-width: 767px) {
			padding-top: 10px;
		}

		@media (max-width: 575px) {
			padding: 10px 0 30px;
		}
	}

	&__title {
		margin-bottom: 30px;
		text-transform: uppercase;

		@media (max-width: 767px) {
			text-transform: none;
		}

		@media (max-width: 480px) {
			font-size: 22px;
		}
	}

	&__description {
		max-width: 295px;
		font-style: italic;
		font-size: 21px;

		@media (max-width: 850px) {
			max-width: none;
		}

		@media (max-width: 480px) {
			font-size: 14px;
		}
	}

	&__picture {
		margin-top: 40px;
		align-self: flex-end;

		@media (max-width: 1199px) {
			position: absolute;
			top: 0;
			right: 0;
			left: 434px;
			text-align: center;
		}

		@media (max-width: 850px) {
			position: static;
			top: auto;
			right: auto;
			left: auto;
			margin: 0 -30px;
			padding: 40px 30px 0;
			background-color: $bg-grey-alt;
		}
	}

	&__img {
		max-height: 750px;

		@media (max-width: 850px) {
			max-height: 500px;
			max-width: 100%;
		}
	}

	&__person {
		position: absolute;
		top: 0;
		right: -500px;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-left: 50px;
		padding-right: 428px;
		background-color: #ffffff;
		transform: skewX(-15deg);

		@media (max-width: 1199px) {
			position: relative;
			top: auto;
			right: auto;
			padding: 40px 66px 0 86px;
			margin: 0 -66px;
			@include no-skew;
		}

		@media (max-width: 991px) {
			padding: 40px 30px 0 50px;
			margin: 0 -30px;
		}
	}

	&__name {
		position: relative;
		max-width: 200px;
		margin-bottom: 20px;
		font-weight: 700;
		font-style: italic;
		font-size: 20px;
		text-transform: uppercase;

		@media (max-width: 1199px) {
			max-width: none;
			margin-bottom: 5px;
		}

		@media (max-width: 767px) {
			text-transform: none;
			font-size: 18px;
		}

		&::before {
			content: "";
			position: absolute;
			top: -28px;
			left: -18px;
			width: 5px;
			height: 23px;
			background-color: $yellow;

			@media (max-width: 1199px) {
				top: -10px;
				@include skew;
			}
		}
	}

	&__inner {
		display: inline-block;
		vertical-align: baseline;
		@include skew-left;

		@media (max-width: 1199px) {
			@include no-skew;
		}
	}

	&__fname {
		@media (max-width: 1199px) {
			display: inline-block;
			vertical-align: baseline;
		}
	}

	&__lname {
		@media (max-width: 1199px) {
			display: inline-block;
			vertical-align: baseline;
		}
	}

	&__position {
		margin-bottom: -3px;
		max-width: 200px;
		font-size: 14px;

		@media (max-width: 1199px) {
			max-width: none;
			font-style: italic;
		}
	}
}

.interview-summary {
	margin-bottom: 80px;
	padding-top: 75px;

	@media (max-width: 767px) {
		margin-bottom: 40px;
		border-bottom: 1px solid #d6d6d6;
		padding-bottom: 35px;
	}

	@media (max-width: 575px) {
		padding-top: 30px;
	}

	&__column {
		columns: 2;

		@media (max-width: 767px) {
			columns: auto;
		}

		@media (max-width: 575px) {
			font-size: 14px;
		}
	}

	&__person {
		display: block;
		font-weight: 700;
		font-style: italic;
	}
}

.interview-content {
	padding-bottom: 45px;

	@media (max-width: 575px) {
		padding-bottom: 20px;
		font-size: 14px;
	}

	&__row {
		margin-bottom: 35px;

		&:last-child {
			margin-bottom: 0;
		}

		@media (max-width: 575px) {
			margin-bottom: 20px;
		}
	}

	&__item {
		position: relative;
		padding-left: 40px;

		@media (max-width: 575px) {
			padding-left: 35px;
		}

		&::before {
			@include accent;
			left: 20px;
		}
	}

	&__question {
		font-weight: 700;
		font-style: italic;

		@media (max-width: 767px) {
			margin-bottom: 30px;
		}

		&::before {
			background-color: $yellow;

			@media (max-width: 767px) {
				width: 4px;
				height: 20px;
				background-color: $grey;
			}
		}
	}

	&__answer {
		&::before {
			background-color: $grey;

			@media (max-width: 767px) {
				display: none;
			}
		}

		p {
			margin-bottom: 25px;

			@media (max-width: 575px) {
				margin-bottom: 15px;
			}
		}
	}
}

.interview {
	&__projects-block {
		margin-top: 40px;
		margin-bottom: 80px;
		@media (max-width: 991px) {
			display: none;
		}
	}
}