.language {
	font-style: italic;
	text-transform: uppercase;

	&_size_big {
		height: 25px;
		font-size: 18px;
		line-height: 25px;
		vertical-align: middle;
	}

	&__item {
		display: inline-block;
		padding: 0 15px;
		vertical-align: top;
		@include skew;

		&_current {
			color: $black;
			background-color: $grey;
		}

		&_link {
			@include link-white;
		}
	}

	&__inner {
		display: block;
		@include skew-left;
	}
}